import { FC } from 'react';
import Link from 'next/link';
import styles from './BtnReadOnline.module.scss';

interface IBtnReadOnline {
  alias: string;
}

const BtnReadOnline: FC<IBtnReadOnline> = ({ alias }) => {
  return (
    <Link prefetch={false} className={`${styles.btn_orange} ${styles.btn_orange_mobWide}`} href={alias}>
      Читать книгу онлайн
    </Link>
  );
};

export default BtnReadOnline;
