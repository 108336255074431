import axios from 'axios';
import { config } from '@/helpers/variables';
import { API_URL } from '@/helpers/variables';
import { IUserDeleteBook } from '@/interfaces/userDeleteBook.interface';
import { IUserRestorePass } from '@/interfaces/userRestorePass.interface';
import { IUserRestorePassMessage } from '@/interfaces/userRestorePassMessage.interface';

export const userService = {
  async putUserData(data: object) {
    const API_PUT_URL = `${API_URL}users/`;
    config;

    axios
      .put(API_PUT_URL, data, config)
      .then((res) => {
        // console.log('RESPONSE ==== : ', res);
      })
      .catch((err) => {
        console.log('ERROR: ====', err);
      });
  },
  async putUserPassword(data: object) {
    const API_PUT_URL = `${API_URL}auth/change-password`;
    config;

    axios
      .put(API_PUT_URL, data, config)
      .then((res) => {
        // console.log('RESPONSE ==== : ', res);
      })
      .catch((err) => {
        console.log('ERROR: ====', err);
      });
  },
  async postUserBook(data: object) {
    const API_POST_URL = `${API_URL}users/library`;
    config;

    axios
      .post(API_POST_URL, data, config)
      .then((res) => {
        // console.log('RESPONSE ==== : ', res);
      })
      .catch((err) => {
        console.log('ERROR: ====', err);
      });
  },

  async postRestorePassMessage(data: IUserRestorePassMessage) {
    const API_POST_URL = `${API_URL}auth/send-restore-message/${data.email}`;
    config;

    axios
      .post(API_POST_URL, data, config)
      .then((res) => {
        console.log('RESPONSE ==== : ', res);
      })
      .catch((err) => {
        console.log('ERROR: ====', err);
      });
  },

  async postRestorePass(data: IUserRestorePass) {
    const API_POST_URL = `${API_URL}auth/restore-password`;
    config;

    axios
      .post(API_POST_URL, data, config)
      .then((res) => {
        console.log('RESPONSE ==== : ', res);
      })
      .catch((err) => {
        console.log('ERROR: ====', err);
      });
  },

  async deleteUserBook(data: IUserDeleteBook) {
    const API_DELETE_URL = `${API_URL}users/library?token=${data.token}&book_id=${data.book_id}`;

    axios
      .delete(API_DELETE_URL)
      .then((res) => {
        // console.log('RESPONSE ==== : ', res);
      })
      .catch((err) => {
        console.log('ERROR: ====', err);
      });
  },
};
